import React, { useEffect, useState } from "react"
import LazyLoad from 'react-lazy-load'
import Geocode from "react-geocode"

const GoogleMap = ({ address, googleMapApiKey }) => {
  Geocode.setApiKey(googleMapApiKey)
  const [googleMapUrl, setGoogleMapUrl] = useState(null)

  const loadGeocode = () => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        setGoogleMapUrl(
          `https://maps.google.co.jp/maps?&output=embed&q=${lat},${lng}&z=13`
        )
      },
      (error) => {
        console.error(error)
      }
    )
  }

  return (
    <LazyLoad offset={0} onContentVisible={() => {loadGeocode()}}>
      <div style={{ height: '315px', width: '100%' }}>
        {googleMapUrl && <iframe width={"100%"} height={"315"} src={googleMapUrl}></iframe>}
      </div>
    </LazyLoad>
  )
}

export default GoogleMap
