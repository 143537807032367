import React,{ useState } from 'react'
import Modal from 'react-modal'

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "absolute",
    width: "90%",
    height: "auto",
    inset: "initial",
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

const TelModal = ({ telNumber, propertyNumber }) => {
  const [tel, setTel] = useState(telNumber)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const telHandleClick = e => {
    setModalIsOpen(true)
  }

  return (
    <>
      <p className="mt-4 text-center">
        <a onClick={telHandleClick} className='cursor-pointer text-base block w-full rounded-xl border-b-2 border-red-500 p-4 px-10 text-center bg-red-400 duration-200 text-white hover:text-white font-bold'>電話でお問合せ(無料)</a>
      </p>

      <Modal 
        style={modalStyle}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="flex justify-center flex-wrap items-center h-full mt-4">
          <p className="text-center text-base">※<span className="text-lg font-bold underline text-green-700">「田舎暮らし物件.comを見た」</span><br />とお伝え下さい。</p>
          <a id="telClickEvent" href={`tel:${tel}`} className='mt-6 cursor-pointer text-base block w-full rounded-xl border-b-2 border-red-500 p-4 px-10 text-center bg-red-400 duration-200 text-white hover:text-white font-bold'>物件不動産に電話する</a>
          <div className="w-full font-bold mt-6 text-center text-base">
            ※電話が繋がらない場合は、<br /><a className="underline text-blue-600" href={`/inquirymail/${propertyNumber}/`}>こちら</a>より問合せください。
          </div>
          <button className="underline mt-6 font-bold text-center" onClick={() => setModalIsOpen(false)}>✕ 閉じる</button>
        </div>
      </Modal>
    </>
  )
}

export default TelModal
